@import "../../styles/variables.less";

.banner {
  position: relative;

    &__banner-main {
        background: transparent;  
      //background-image: linear-gradient(67.13deg, rgba(54, 58, 137, 0.9) 0%, rgba(73, 114, 181, 0.9) 100%);
        min-height: 600px;
        height: 800px;

        .background-degrade {
            background-image: linear-gradient(67.13deg, rgba(54, 58, 137, 0.9) 0%, rgba(73, 114, 181, 0.9) 100%), url("../../../public/images/background-back.png");
            min-height: 600px;
            height: 800px;
            transform: rotate(1deg);
            width: 120vw;
            position: absolute;
            top: -30px;
            left: -27.29px;
            right: 0;
            bottom: 0;
        }

        @media only screen and (max-width: @header-mobile-width) {
            height: 800px;
        }

        &_wrapper{
            width: 100%;
            display: flex;
            align-items: center;
            margin: 0 auto;
            justify-content: center;
            padding: 64px 0 48px 0;
          
            @media only screen and (max-width: @header-mobile-width) {
              margin-top: 0
            }

            &-text {
                margin: 0 auto;
                text-align: center;
                .title {
                    .font-size-main();

                    @media only screen and (max-width: @header-mobile-width) {
                      .font-size-main(
                        @font-size: 2rem;
                      );

                    }
                }
            }

            &-image {
              height: 400px;
              position: absolute;
              top: 150px;
              width: 600px;
            }
           
          }

        &_grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 24px;
          padding-top: 40px;

          @media (max-width: @header-mobile-width) and (max-width: @header-latop-width) {
            padding: 40px;
            display: flex;

            .grid-two {
              img {
                height: 100%;
              }
            }


            .grid-one,
            .grid-three {
              display: none;
            }
          }

          @media screen and (min-width:770px) and (max-width:1200px) {
            padding: 40px;
          }

          .grid-one,
          .grid-two,
          .grid-three {
            height: 500px;
          }

          .grid-two {
            margin-top: -24px;
          }

        }
    }
}
    
@primary-color: #2c469d;@font-size-base: 16px;@border-radius-base: 20px;