@import "../../styles/variables.less";

.content {
    padding-bottom: 140px;
    margin-bottom: 20px;

    @media only screen and (max-width: 800px) {
        padding: 0 20px;
        margin-bottom: 140px;
        flex-wrap: wrap;
    }

    > div {
        width: 100%;
    }
    
    > ul.actions {
        width: 300px;
        @media only screen and (max-width: 800px) {
            width: 100%;
        }
    }

    .image {
        width: 100%;
        height: auto;
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .text {
        background: #363A89;
        box-shadow: 4px 4px 16px rgba(54, 58, 137, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 48px;

        > h1 {
            .font-size-secundary(
                @color: #fff;
            );
            text-align: center;
            max-width: 1200px;
        }
        
        > div {
            font-size: 1.2em;
            > ul {
                margin-left: 40px !important;
            }
        }
    }

    &__text {

    }

    .politica-privacidade_content {
        padding: 80px;

        @media only screen and (max-width: @header-mobile-width) {
            padding: 40px;
        }

        &_text {

            .title {
                .font-size-main(
                    @color: #292929;
                );
                text-transform:  capitalize;
                text-align: center;

                @media only screen and (max-width: @header-mobile-width) {
                    .font-size-secundary(
                        @color: #292929;
                    );
        
                }
            }

            span {
                font-weight: 700;
                display: block;
                padding: 0.5rem 0;
            }
        }
    }

    .content-video {
        overflow-x: hidden;

        &_content {


            @media screen and (max-width: 1206px) {
                padding: 0 40px;
            }

            @media screen and (max-width: @header-mobile-width) {
                padding: 0;
            }
            
            &_text {

                padding-bottom: 1rem;

                h1 {
                    .font-size-secundary(
                        @color: #292929;
                    );
                }
            }
        }
    }
    .iframe-content{ 

        position: absolute;
        right: 60px;

        @media screen and (max-width: 1800px) {
            right: 90px;
        }

        @media screen and (max-width: 1197px) {
            position: relative;
            width: 100%;
            right: 0;

        }
    }
    .iframe-fiduciatel {
        width: 650px;

        @media screen and (max-width: 1197px) {
            width: 100%;
        }

        iframe {
            height: 550px;
            width: 100%;

            @media screen and (max-width: 1197px) {
                width: 100%;
            }
        }
    }

    &__image-left {

    }
    &__image-right {

    }
    &__image-top {

    }
    &__image-bottom {

    }

    &__text-service {
        > h1 {
            font-size: 2em;
            font-weight: normal;
            letter-spacing: .01em;
        }
        &__title-border {
            height: 4px;
            width: 8vw;
            margin-top: -1em;
            margin-bottom: 1em;
            background: rgb(255,255,255);
        }
        .ant-btn {
            height: 50px;
            min-width: 190px;
            > span {
                text-transform: uppercase;
                letter-spacing: .05em;
                font-size: 1.2em;
                padding: 0 20px;
            }
        }
    }

    .actions__item {
        background-color: @primary-color;
        text-align: center;
        border-color: @primary-color;
        h4 {
            color: #ffffff;
        }
        a {
            color: @secondary-color;
        }
        &:hover {
            cursor: pointer;
            background-color: @secondary-color;
            color: #000000;
            h4 {
                color: #000000;
            }
            a {
                color: @primary-color;
            }
        }
    }
}

@primary-color: #2c469d;@font-size-base: 16px;@border-radius-base: 20px;