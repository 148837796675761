
@site-background: #ffffff;

@primary-color: #1178FF;
@primary-color-text: #fff;
@secundary-color-text: #292929;
@secondary-color: #d1d1d1;

@site-max-width: 1200px;
@header-size: 100px;
@logo-width: 200px;

@primary-link-color: @primary-color;
@primary-link-hover-color: darken(@primary-color, 25%);

@page-shadow-size: 25px;
@page-shadow-color: lighten(@primary-color, 50%);
@page-background: @site-background;

@header-background: #2c469d;
@header-menu-color: #ffffff;
@header-menu-color-hover: @primary-color;
@header-line-height-fix: 0;
@header-line-height: @header-size;
@header-size-mobile: @header-size;
@header-mobile-width: 768px;
@header-latop-width: 991px;
@header-desktop-width: 1200px;

@mobile-menu-padding-vertical: 25px;
@mobile-menu-padding-horizontal: 25px;
@mobile-menu-background: @site-background;

@footer-color: inherit;
@footer-background: #fff;

@actions-item-border-color: @site-background;
@actions-item-border-width: 2px;
@actions-item-border-radius: 2px;
@actions-item-background: rgba(0,0,0,.25);
@actions-item-text-color: @site-background;
@actions-item-text-color-hover: #000000;
@actions-item-link-color: @site-background;
@actions-item-link-color-hover: @primary-color;
@actions-item-background-hover: @site-background;

@listing-service-list-service-bg: rgba(0,0,0,0.35);


@banner-background: @header-background;
@banner-secondary-darken-bg-color: rgba(0,0,0,0.35);
@banner-secondary-text-shadow: rgba(0,0,0,0.25);

@banner-sub-banner-bg: @site-background;
@banner-sub-banner-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
@banner-sub-banner-color: rgba(0, 0, 0, 0.8);

.font-size-main(@font-size: clamp(3.2rem, 2.5vw, 5rem), @color: @primary-color-text, @letter-spacings: -0.1rem, @line-height: 1, @font-weight: 700) {
    font-size: @font-size;
    color: @color;
    line-height: @line-height;
    letter-spacing: @letter-spacings;
    font-weight: @font-weight;
}

.font-size-secundary(@font-size: 2.5rem, @color: @primary-color-text, @letter-spacings: -0.0625rem, @line-height: 1, @font-weight: 500) {
    font-size: @font-size;
    color: @color;
    line-height: @line-height;
    letter-spacing: @letter-spacings;
    font-weight: @font-weight;
}

img {
    width: 100%;
    object-fit: cover;
    min-height: 100%;
    max-width: 100%;
}

