@import "../../../styles/variables.less";


.listing {
    &__list-of-services {
        justify-content: center !important;
        padding: 20px 0 80px 0;

        .square-at-top {
            background: linear-gradient(67.4deg, #343385 0%, #4C77BA 99.61%);
            height: 10px;
            width: 100%;
            margin-top: -30px;
            left: 8px;
            position: static;
        }

        &__items {
            padding-top: 80px;

            &__item {
                box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
                align-items: center;
                justify-content: center;
                display: flex;
                padding: 10px 16px;
                gap: 8px;
                text-align: center;
                height: 150px;


                /*
                &::before {
                    content: '';
                    position: absolute;
                    background: linear-gradient(67.4deg, #343385 0%, #4C77BA 99.61%);
                    height: 10px;
                    width: 389px;
                    top: 40px;
                    left: 8px;
                }
                */
            }
        }
        
       
    }

   
}

@primary-color: #2c469d;@font-size-base: 16px;@border-radius-base: 20px;