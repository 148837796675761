@import "../../styles/variables.less";

.listing {
    margin: 0 auto;
    padding: 10vw 0 80px 0;

    @media screen and (max-width: 563px) {
        padding: 30vw 20px 20vw !important;
    }

    @media (max-width: @header-mobile-width) and (max-width: @header-latop-width) {
        padding: 20vw 20px 20vw;
    }

    @media screen and (min-width:770px) and (max-width:1200px) {
        padding: 20vw 40px 10vw;
    }

    @media screen and (min-width:1650px) {
        padding: 10vw 40px 0vw;
    }

    h1 {
        .font-size-secundary(@color: #292929, @font-weight: 700);
        text-align: center;
    }
   
    .darken-bg {
        background: rgba(44, 70, 157, 0.8);
        height: 400px;
        position: absolute;
        right: -90px;
        width: 300px;
        top: 180px;
        z-index: -1;
    }

    .bg-image-work {
        height: 500px;
        margin-top: 24px;
        margin-left: 32px;

        @media screen and (max-width:770px) {
            margin-left: 0 !important;
        }
    }

    .heading {
        > h1 {
            margin-bottom: 0;
        }
    }

    .content-heading {
        .font-size-secundary(
            @color: @header-background;
        );
        padding-top: 9rem;
        text-align: center;
    }

    .content-background {
        background-color: red;
    }
 
}

@primary-color: #2c469d;@font-size-base: 16px;@border-radius-base: 20px;