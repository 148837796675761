@import '../../../styles/variables.less';

.contact-form {
    //margin: 50px 50px;
    padding: 80px 0;
    background: linear-gradient(67.4deg, #343385 0%, #4C77BA 99.61%);   
    clip-path: polygon(0 3%, 100% 0%, 100% 100%, 0 100%);

    @media only screen and (max-width: 800px) {
        margin: 20px auto;
        margin-bottom: 120px;
    }

    &-title {
        color: @primary-color-text;
        text-align: center;
        font-size: 2.5rem;
        margin-bottom: 2rem;
    }
    
    .ant-form {
        margin: 0 auto;
        max-width: 50%;
        
        .ant-input {
            border-radius: 8px !important;
        }
        .ant-input {
            &::placeholder {
                color: @secundary-color-text !important;
                font-weight: 600 !important;
            }
        }
        
        @media screen and (max-width: 768px) {
            max-width: 80%;
        }

        .ant-col-hide {
            display: none;
        }
        
        button {
            border-color:  linear-gradient(89.71deg, #E7B042 0%, #EDAC2B 100%);
            background: linear-gradient(89.71deg, #E7B042 0%, #EDAC2B 100%);
            height: 40px;
            padding: 5px 15px;
            border-radius: 4px;

            &:hover {
                border: none;
                border-color:  linear-gradient(89.71deg, #E7B042 0%, #EDAC2B 100%);
                opacity: 0.8;
            }
        }
    }
}

@primary-color: #2c469d;@font-size-base: 16px;@border-radius-base: 20px;