/*************
 * Dark Mode *
 *///////////*/
//@import '~antd/lib/style/themes/dark.less';

@import '~antd/dist/antd.less';

@import '~@animated-burgers/burger-slip/dist/styles.css';
@import '~sal.js/dist/sal.css';

@import "./variables.less";


* {
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html,
body {
    background-color: @site-background;
    position: relative;

}
.ant-layout {
    padding-top: @header-size;
    background-color: @page-background;
    min-height: 100vh;
    overflow: hidden;

    @media only screen and (max-width: @header-mobile-width) {
        padding-top: @header-size-mobile;
    }
}

.ant-layout-content {
    background-color: @page-background !important;
    z-index: 2;

    a {
        color: @primary-link-color;
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
            color: @primary-link-hover-color;
            text-decoration: underline;
        }
    }
}

ul, li {
    list-style: none;
    text-decoration: none;
}

.styles_container { // mudar o nome da class ~~ container
    width: 100%;
    max-width: 1200px;
    margin: 0 auto !important;
    z-index: 200;
}
@primary-color: #2c469d;@font-size-base: 16px;@border-radius-base: 20px;